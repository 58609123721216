<template>
  <div class="quan">
    <div class="a-top">
      <img :src="siteInfo.logo_white_account_login" width="10%" />
    </div>
    <div class="midd">
      <div class="midd-l">
        <img src="../assets/image//atu.jpg" width="100%" />
      </div>
      <div class="midd-r">
        <div class="login-form-box">
          <a-tabs v-model="type">
            <a-tab-pane key="1" tab="账号密码登录">
              <div class="a2">
                <div class="a-l"><img src="../assets/image/a1.png" /></div>
                <input
                  type="text"
                  v-model="form.mobile"
                  :maxLength="11"
                  class="inp"
                  placeholder="请输入用户名"
                />
              </div>
              <div class="a2">
                <div class="a-l"><img src="../assets/image/a2.png" /></div>
                <input
                  type="password"
                  v-model="form.pwd"
                  class="inp"
                  placeholder="请输入密码"
                />
              </div>
              <div class="a3">
                <button @click="loginAct2" type="primary" class="btn">
                  立即登录
                </button>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="验证码登录" force-render>
              <div class="a2">
                <div class="a-l"><img src="../assets/image/a3.png" /></div>
                <input
                  type="text"
                  v-model="form.mobile"
                  class="inp"
                  :maxLength="11"
                  placeholder="请输入手机号"
                />
              </div>
              <div class="a2">
                <div class="a-l"><img src="../assets/image/a2.png" /></div>
                <input
                  type="text"
                  v-model="form.code"
                  class="inp"
                  id="inp"
                  :maxLength="6"
                  placeholder="请输入验证码"
                /><countdown-djs
                  :mobile="form.mobile"
                  :code="code"
                  :type="'login'"
                ></countdown-djs>
              </div>
              <div class="a3">
                <button @click="loginAct" type="primary" class="btn">
                  立即登录
                </button>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
    <div class="bj"><img src="../assets/image/bj.png" width="100%" /></div>
  </div>
</template>

<script>
import countdownDjs from "../components/countdown/djs.vue";
export default {
  components: {
    countdownDjs,
  },
  data() {
    return {
      type: "1",
      loading: false,
      code: "",
      form: {
        mobile: "",
        code: "",
        pwd: "",
      },
      siteInfo: null,
    };
  },
  created() {
    if (localStorage.getItem("account-token")) {
      this.$router.push("/mendian");
    } else {
      this.getSiteInfo();
      this.getCode();
    }
  },

  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.keyDown);
  },
  methods: {
    getSiteInfo() {
      this.$http
        .api("platform/siteInfo")
        .then((res) => {
          this.siteInfo = res.site;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getCode() {
      this.$http
        .api("platform/account/getCode", {})
        .then((res) => {
          this.code = res.code;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    loginAct() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/account/login", {
          mobile: this.form.mobile,
          code: this.form.code,
          token: this.code,
        })
        .then((res) => {
          this.loading = false;
          localStorage.setItem("account-token", res.auth_token);
          this.$message.success("登录成功", 1, () => {
            this.$router.push("/mendian");
          });
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
    loginAct2() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/account/pwdLogin", {
          mobile: this.form.mobile,
          pwd: this.form.pwd,
        })
        .then((res) => {
          this.loading = false;
          localStorage.setItem("account-token", res.auth_token);
          this.$message.success("登录成功", 1, () => {
            this.$router.push("/mendian");
          });
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        if (this.type == "1") {
          this.loginAct2();
        } else {
          this.loginAct();
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>

<style>
a {
  text-decoration: none;
}
img {
  border: 0;
}
input {
  border: 0;
  outline: 0;
  font-size: 14px;
  color: #000;
}
body {
  font-family: microsoft Yahei;
  background: #f7f8fa;
  background-size: cover;
}
em,
i {
  font-style: normal;
}
::-webkit-input-placeholder {
  color: #b7b7b7;
}

.all {
  width: 100%;
  margin: 0 auto;
}

/*登录*/
.quan {
  background: linear-gradient(to right, #3065d5, #5654cf);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}
.a-top {
  width: 100%;
  margin: 5% auto 40px;
  text-align: center;
}
.midd {
  width: 60%;
  margin: 0 auto;
  padding: 3% 0px;
  border-radius: 50px;
  background: #fff;
  display: flex;
  align-items: center;
}
.midd .midd-l {
  width: 46%;
  padding: 0px 2%;
}
.midd .midd-r {
  width: 45%;
  margin-right: 5%;
}

.midd .midd-r .a1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 57px;
}
.midd .midd-r .a1 .ss {
  width: 50%;
  text-align: center;
  font-size: 20px;
  padding: 15px 0px;
  border-bottom: 1px solid #dfdfdf;
}
.midd .midd-r .a1 #xuan {
  color: #387df6;
  border-bottom: 2px solid #387df6;
}
.midd .midd-r .a2 {
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
  padding: 12px 0;
  margin-bottom: 29px;
  display: flex;
  align-items: center;
}
.midd .midd-r .a2 .a-l {
  width: 50px;
  text-align: center;
  position: absolute;
  z-index: 2;
  border-right: 1px solid #dfdfdf;
}
.midd .midd-r .a2 .inp {
  width: 100%;
  font-size: 16px;
  padding: 4px 10px 0px 65px;
  box-sizing: border-box;
}
.midd .midd-r .a2 #inp {
  width: 65%;
}

.midd .midd-r .a3 {
  width: 100%;
  text-align: center;
  margin-top: 56px;
}
.midd .midd-r .btn {
  width: 50%;
  background: linear-gradient(to right, #3172f4, #4092f6);
  padding: 15px 0px;
  border-radius: 30px;
  border: none;
  color: #fff;
  font-size: 16px;
}
.midd .midd-r .btn:hover {
  opacity: 0.8;
}

.bj {
  width: 100%;
  position: absolute;
  z-index: -1;
  bottom: 0px;
  left: 0px;
}

.login-form-box {
}
</style>
